<template>
  <div>
    <p class="mb-3">
      Determine the appropriate equilibrium constant expression for the following reaction:
    </p>

    <p class="mb-0 pl-14">
      <stemble-latex :content="`$\\ce{A(aq) <=> ${equivBint}B(aq) + ${equivCint}C(aq)}$`" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="mb-2 mt-2"
          :value="option.value"
        >
          <template #label>
            <latex-number style="font-size: 14px" :number="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question170a',
  components: {
    StembleLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    equivB() {
      return this.taskState.getValueBySymbol('equivB').content;
    },
    equivC() {
      return this.taskState.getValueBySymbol('equivC').content;
    },
    equivBint() {
      return this.equivB.toInt();
    },
    equivCint() {
      return this.equivC.toInt();
    },
    options1() {
      return [
        {
          expression:
            '\\text{K}=\\cfrac{[\\text{A}]}{[\\text{B}]^{' +
            this.equivBint +
            '}[\\text{C}]^{' +
            this.equivCint +
            '}}',
          value: 'RoverP',
        },
        {
          expression:
            '\\text{K}=\\cfrac{[\\text{B}]^{' +
            this.equivBint +
            '}[\\text{C}]^{' +
            this.equivCint +
            '}}{[\\text{A}]}',
          value: 'PoverR',
        },
        {
          expression: '\\text{K}=\\cfrac{\\text{[B][C]}}{[\\text{A}]}',
          value: 'PoverRnoStoich',
        },
        {
          expression: '\\text{K}=\\cfrac{\\text{[A]}}{\\text{[B][C]}}',
          value: 'RoverPnoStoich',
        },
      ];
    },
  },
};
</script>
